import Vue from 'vue'
import Vuex from 'vuex'
import apiservice from '@/services/apiservice'
import jwtDecode from 'jwt-decode'
import daLang from '@/localization/da'
import enLang from '@/localization/en'
import svLang from '@/localization/sv'
import deLang from '@/localization/de'
import { getShopDomainFromJwt, getLanguageByShopDomain } from '@/VuexStoreMethods'
import { getExpireDay } from '@/GeneralMethods'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    currentGalleryVersion: 6,
    toggledToProd: false,
    apiBaseUrl: process.env.VUE_APP_API_BASE,
    isDev: process.env.VUE_APP_ISDEV === 'true',
    availableLanguages: ['de', 'da', 'en', 'sv'],
    previewScreenData: null,
    previewPictureData: null,
    isGalleryOwner: false,
    galleryIsActive: false,
    imageChanging: false,
    imageOpened: false,
    accessToken: null,
    loggingIn: false,
    loginError: null,
    imageStateChanging: false,
    showDeleteInform: false,
    galleryActiveImageIndex: 0,
    currentOrderNumber: null,
    currentShopDomain: null,
    language: 'en',
    languageCode: 1033,
    langModule: enLang,
    selectedImg: null,
    imgChildHeight: null,
    showSlideOneGallery: false,
    showSlideAllGalleries: false,
    showSlideModal: false,
    showGalleryModal: false,
    slideShutdown: false,
    externalFullscreenActivated: false,
    newImageAdded: false,
    images: {},
    lastFile: null,
    lastDate: null,
    loginName: '',
    loginPassword: '',
    currentYPos: 0,
    orders: [],
    currentOrder: null,
    isSafari: false,
    isFireFox: false,
    isChrome: false,
    isMobile: false,
    lastFrameWidth: 0,
    lastFrameHeight: 0,
    extraWaitTime: 0,
    firstGalleryFetched: false,
    jwtOrderObject: null,
    loginExpire: null,
    fetchingToken: false,
    anonAccessToken: null,
    menuFullscreen: false,
    positionCross: false,
    langExpanded: false,
    menuClosed: true,
    galleryDate: null,
    overlayScreenState: null,
    overlayPictureState: null,
    lastOverlayTemplate: '',
    overlayScreenLoading: true,
    overlayPictureLoading: true,
    overlayReady: false,
    newImagesAvailable: false
  },
  mutations: {
    changeToggledToProd(state, value) {
      state.toggledToProd = value
      if (value) {
        localStorage.setItem('toggledProd', '1')
      } else {
        localStorage.removeItem('toggledProd')
      }
    },
    changeImages(state, value) {
      state.images = value
    },
    changeOrders(state, value) {
      state.orders = value
    },
    changeCurrentGalleryVersion(state, value) {
      state.currentGalleryVersion = value
    },
    changePreviewScreenData(state, value) {
      state.previewScreenData = value
    },
    changePreviewPictureData(state, value) {
      state.previewPictureData = value
    },
    changeGalleryDate(state, value) {
      state.galleryDate = value
    },
    changeMenuClosed(state, value) {
      state.menuClosed = value
    },
    changeLangExpanded(state, value) {
      state.langExpanded = value
    },
    changePositionCross(state, value) {
      state.positionCross = value
    },
    changeMenuFullscreen(state, value) {
      state.menuFullscreen = value
    },
    changeLanguage(state, value) {
      state.language = value
    },
    changeAnonAccessToken (state, value) {
      state.anonAccessToken = value
    },
    changeJwtOrderObject (state, value) {
      state.jwtOrderObject = jwtDecode(value)

      if (Object.prototype.hasOwnProperty.call(state.jwtOrderObject, 'shopDomain')) state.currentShopDomain = state.jwtOrderObject.shopDomain

      if (Object.prototype.hasOwnProperty.call(state.jwtOrderObject, 'orderNumber')) state.currentOrderNumber = state.jwtOrderObject.orderNumber
    },
    changeFirstGalleryFetched (state, value) {
      state.firstGalleryFetched = value
    },
    changeExtraWaitTime (state, value) {
      state.extraWaitTime = value
    },
    newImage (state, value) {
      state.imageChanging = value
    },
    changeCurrentOrder (state, value) {
      state.currentOrder = value
    },
    changeSlideShutdown (state, value) {
      state.slideShutdown = value
    },
    changeShowGalleryModal (state, value) {
      state.showGalleryModal = value
    },
    changeCurrentYPos (state, value) {
      state.currentYPos = value
    },
    changeNewImageAdded (state, value) {
      if (value) state.extraWaitTime = 0

      state.newImageAdded = value
    },
    changeExternalFullscreenActivated (state, value) {
      state.externalFullscreenActivated = value
    },
    changeShowSlideModal (state, value) {
      state.showSlideModal = value
    },
    changeLastFile (state, value) {
      state.lastFile = value
    },
    changeLastDate (state, value) {
      state.lastDate = value
    },
    changeIsGalleryOwner (state, value) {
      state.isGalleryOwner = value
    },
    changeImageState (state, value) {
      state.imageStateChanging = value
    },
    changeGalleryActiveImageIndex (state, value) {
      state.galleryActiveImageIndex = value
    },
    changeCurrentOrderNumber (state, value) {
      state.currentOrderNumber = value
    },
    changeCurrentShopDomain (state, value) {
      state.currentShopDomain = value
    },
    changeShowDeleteInform (state, value) {
      state.showDeleteInform = value
    },
    changeImageOpened (state, value) {
      state.imageOpened = value
    },
    changeSelectedImg (state, value) {
      state.selectedImg = value
    },
    changeImgChildHeight (state, value) {
      state.imgChildHeight = value
    },
    changeOverlayPictureState(state, value) {
      state.overlayPictureState = value
    },
    changeOverlayScreenState(state, value) {
      state.overlayScreenState = value
    },
    changeLastOverlayTemplate(state, value) {
      state.lastOverlayTemplate = value
    },
    changeOverlayScreenLoading(state, value) {
      state.overlayScreenLoading = value
    },
    changeOverlayPictureLoading(state, value) {
      state.overlayPictureLoading = value
    },
    changeNewImagesAvailable(state, value) {
      state.newImagesAvailable = value
    },
    showSlide (state, value) {
      state.showSlideModal = false
      state.galleryIsActive = true
      state.showSlideOneGallery = value
      state.showSlideAllGalleries = !value
      state.showGalleryModal = true
    },
    registerNewOrder(state, value) {
      const orderNr = value.mys_extordernumber
      const shopDomain = value.mys_shopdomain

      if (state.currentOrderNumber !== orderNr) {
        state.lastFile = null
        state.images = {}
        state.firstGalleryFetched = false
        state.currentOrderNumber = orderNr
      }

      if (shopDomain) state.currentShopDomain = shopDomain
    },
    logout (state) {
      sessionStorage.removeItem('accessToken')
      sessionStorage.removeItem('storedLoginExpire')
      state.accessToken = null
      state.anonAccessToken = null
      state.loginExpire = null
      state.isGalleryOwner = false
      state.images = {}
      state.lastFile = null
      state.lastDate = null
      state.currentShopDomain = null
      state.currentOrderNumber = null
      state.currentYPos = 0
      state.lastFrameHeight = 0
      state.lastFrameWidth = 0
      state.orders = []
      state.currentOrder = null
      state.selectedImg = null
      state.loginName = ''
      state.loginPassword = ''
      state.extraWaitTime = 0
      state.firstGalleryFetched = false
      state.jwtOrderObject = null
      state.positionCross = false
      state.menuClosed = true
      state.galleryDate = null
      state.overlayPictureState = null
      state.overlayScreenState = null
      state.lastOverlayTemplate = ''
      state.newImagesAvailable = false
    },
    exitModal (state) {
      state.showGalleryModal = false
      state.imageOpened = false
      state.showSlideOneGallery = false
      state.showSlideAllGalleries = false
      state.showSlideModal = false
      state.galleryIsActive = false
    },
    exitSlideshow (state) {
      if (state.galleryIsActive) {
        state.showSlideOneGallery = false
        state.showSlideAllGalleries = false
        state.galleryIsActive = false
        state.slideShutdown = true
        state.imageChanging = false
      }
    },
    exitImage (state) {
      state.showGalleryModal = false
      state.imageOpened = false
    },
    destroyOverlayStates (state) {
      state.overlayPictureState = null
      state.overlayScreenState = null
    },
    setBrowserInfo(state, { browserType, value }) {
      state[browserType] = value
    },
    setMobileStatus(state, isMobile) {
      state.isMobile = isMobile
    }
  },
  actions: {
    initStore({ commit }) {
      this.dispatch('getDeviceInfo')
      if (localStorage.getItem('toggledProd') !== null) {
        commit('changeToggledToProd', true)
      }
    },
    getDeviceInfo({ commit }) {
      const userAgentString = navigator.userAgent
      commit('setBrowserInfo', { browserType: 'isFireFox', value: userAgentString.indexOf('Firefox') > -1 })
      commit('setBrowserInfo', { browserType: 'isSafari', value: userAgentString.indexOf('Safari') > -1 })
      commit('setBrowserInfo', { browserType: 'isChrome', value: userAgentString.indexOf('Chrome') > -1 })
      commit('setMobileStatus', /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgentString))
    },
    async fetchOrders() {
      const ordersResponse = await apiservice.getApi('/user/galleryorders')
      if (ordersResponse.status >= 300) return

      const ordersResult = await ordersResponse.json()
      this.dispatch('sortOrders', ordersResult)
    },
    sortOrders({ state }, orderResult) {
      if (orderResult.length === 0) return

      orderResult.sort((a, b) => new Date(b.mys_date) - new Date(a.mys_date)).forEach(order => {
        order.expireDay = getExpireDay(order.mys_imageexpiration)
        order.expired = order.expireDay <= 0
        order.warnExpire = !order.expired && order.expireDay <= 5
        order.mys_date = new Date(order.mys_date)

        const ix = state.orders.findIndex(x => x.mys_extordernumber === order.mys_extordernumber)
        if (ix === -1) {
          state.orders.push(order)
        } else {
          state.orders[ix].mys_date = order.mys_date
        }
      })
    },
    registerLanguageFromUrlQuery({ state, commit }, to) {
      const langParm = to.query.lang

      if (!langParm) {
        this.dispatch('registerDefaultLanguage')
        return
      }

      const langParmValid = state.availableLanguages.includes(langParm)
      if (langParmValid) localStorage.setItem('currentLang', langParm)

      const storedLang = localStorage.getItem('currentLang')

      if (langParmValid) {
        commit('changeLanguage', storedLang !== null ? storedLang : langParm)
      } else {
        if (storedLang !== null) commit('changeLanguage', storedLang)
      }
    },
    registerDefaultLanguage({ state, commit }) {
      let language = localStorage.getItem('currentLang')

      if (!language) {
        const shopDomain = state.currentShopDomain || getShopDomainFromJwt(state)
        if (!shopDomain) return

        commit('changeCurrentShopDomain', shopDomain)
        language = getLanguageByShopDomain(shopDomain)
      }

      if (language !== state.language) {
        commit('changeLanguage', language)
        localStorage.setItem('currentLang', language)
      }
    },
    registerLogin({ state, commit }, loginData) {
      state.accessToken = loginData.token
      sessionStorage.setItem('accessToken', loginData.token)
      state.loginName = loginData.username
      state.loginPassword = loginData.password
      state.isGalleryOwner = true

      commit('changeJwtOrderObject', loginData.token)
      this.dispatch('handleExpiration')
    },
    newImagesMetaData({ state }, dto) {
      state.images = { ...state.images, [dto.index]: dto.metaData }
    },
    handleExtraWaitTime({ state }) {
      if (state.newImagesAvailable) {
        state.extraWaitTime = state.extraWaitTime > 0 ? 0 : Math.max(state.extraWaitTime - 1, -5)
      } else if (state.extraWaitTime < 300) {
        state.extraWaitTime++
      }
      state.newImagesAvailable = false
    },
    changeFrameSize ({ state }, size) {
      state.lastFrameWidth = size.width + 1
      state.lastFrameHeight = size.height + 1
    },
    handleExpiration ({ state }) {
      const jwt = state.jwtOrderObject
      if (jwt === null) return

      if (!Object.prototype.hasOwnProperty.call(jwt, 'exp')) return

      state.loginExpire = jwt.exp * 1000
      sessionStorage.setItem('storedLoginExpire', state.loginExpire)
    },
    refreshToken ({ state, commit }) {
      if (state.accessToken === null) {
        const storedToken = sessionStorage.getItem('accessToken')
        if (storedToken !== null) state.accessToken = storedToken
      }

      if (state.accessToken === null) return

      commit('changeJwtOrderObject', state.accessToken)

      if (state.loginExpire === null) {
        const storedLoginExpire = sessionStorage.getItem('storedLoginExpire')
        if (storedLoginExpire !== null) state.loginExpire = BigInt(storedLoginExpire)
      }
    },
    async fetchAccessToken ({ state }) {
      if (state.fetchingToken || state.loggingIn) return

      state.fetchingToken = true
      try {
        this.dispatch('refreshToken')
        let shouldReFetch = false
        const user = state.loginName; const pw = state.loginPassword
        if (state.accessToken !== null) {
          if (state.loginExpire !== null) shouldReFetch = Date.now() >= state.loginExpire && user !== '' && pw !== ''
          else shouldReFetch = user !== '' && pw !== ''
        } else {
          shouldReFetch = user !== '' && pw !== ''
        }

        if (shouldReFetch) {
          const response = await apiservice.postApiAuth('/gallerytoken', { Email: user, Password: pw })
          const authSuccessful = response.status === 200
          if (authSuccessful) {
            const { token, orders } = await response.json()
            state.isGalleryOwner = true
            state.accessToken = token
            state.orders = []
            this.dispatch('sortOrders', orders)
            this.dispatch('handleExpiration')
          }
        }
      } catch {

      }
      state.fetchingToken = false
    },
    handleAnonResponseResult({ state, commit }, anonResponse) {
      state.anonAccessToken = anonResponse.token
      commit('changeJwtOrderObject', anonResponse.token)
    }
  },
  getters: {
    currentGalleryVersion: state => state.currentGalleryVersion,
    previewPictureData: state => state.previewPictureData,
    previewScreenData: state => state.previewScreenData,
    apiBaseUrl: state => state.toggledToProd ? 'https://orderimages.blob.core.windows.net' : state.apiBaseUrl,
    isDev: state => state.isDev,
    imageChanging: state => state.imageChanging,
    isGalleryOwner: state => state.isGalleryOwner,
    galleryIsActive: state => state.galleryIsActive,
    imageStateChanging: state => state.imageStateChanging,
    galleryActiveImageIndex: state => state.galleryActiveImageIndex,
    currentOrderNumber: state => state.currentOrderNumber,
    currentShopDomain: state => state.currentShopDomain,
    showDeleteInform: state => state.showDeleteInform,
    accessToken: state => state.accessToken,
    imageOpened: state => state.imageOpened,
    selectedImg: state => state.selectedImg,
    imgChildHeight: state => state.imgChildHeight,
    images: state => state.images,
    lastFile: state => state.lastFile,
    lastDate: state => state.lastDate,
    showSlideModal: state => state.showSlideModal,
    showGalleryModal: state => state.showGalleryModal,
    showSlideOneGallery: state => state.showSlideOneGallery,
    showSlideAllGalleries: state => state.showSlideAllGalleries,
    externalFullscreenActivated: state => state.externalFullscreenActivated,
    newImageAdded: state => state.newImageAdded,
    loginName: state => state.loginName,
    loginPassword: state => state.loginPassword,
    currentYPos: state => state.currentYPos,
    orders: state => state.orders,
    currentOrder: state => state.currentOrder,
    slideShutdown: state => state.slideShutdown,
    isSafari: state => state.isSafari,
    isFireFox: state => state.isFireFox,
    isChrome: state => state.isChrome,
    isMobile: state => state.isMobile,
    lastFrameWidth: state => state.lastFrameWidth,
    lastFrameHeight: state => state.lastFrameHeight,
    extraWaitTime: state => state.extraWaitTime,
    firstGalleryFetched: state => state.firstGalleryFetched,
    jwtOrderObject: state => state.jwtOrderObject,
    loginExpire: state => state.loginExpire,
    anonAccessToken: state => state.anonAccessToken,
    language: state => state.language,
    menuFullscreen: state => state.menuFullscreen,
    positionCross: state => state.positionCross,
    langExpanded: state => state.langExpanded,
    menuClosed: state => state.menuClosed,
    galleryDate: state => state.galleryDate,
    overlayPictureState: state => state.overlayPictureState,
    overlayScreenState: state => state.overlayScreenState,
    lastOverlayTemplate: state => state.lastOverlayTemplate,
    overlayScreenLoading: state => state.overlayScreenLoading,
    overlayPictureLoading: state => state.overlayPictureLoading,
    toggledToProd: state => state.toggledToProd,
    newImagesAvailable: state => state.newImagesAvailable,
    overlayReady: state => {
      return !state.overlayPictureLoading && !state.overlayScreenLoading && state.overlayScreenState !== null && state.overlayPictureState !== null
    },
    langModule: state => {
      switch (state.language) {
        case 'da': return daLang
        case 'en': return enLang
        case 'de': return deLang
        case 'sv': return svLang
        default: return enLang
      }
    },
    languageCode: state => {
      switch (state.language) {
        case 'da': return 1030
        case 'en': return 1033
        case 'de': return 1031
        case 'sv': return 1053
        default: return 1033
      }
    }
  }
})
